<template lang="html">
	<div class="clearfix h-screen">
		<div class="flex h-full w-full overflow-hidden">
			RESET PASSWORD
		</div>
	</div>
</template>

<script>
  export default {
    name: 'ResetPassword',
    components: {},
    data() {
      return {
        btnActive: false,
        isSuccess: false,
        userData: {}
      }
    },
    watch: {
      'userData': {
        handler(value) {
          const data = {...value}
          this.btnActive = !!(data.password && data.confirmPassword && (data.password === data.confirmPassword))
        },
        deep: true
      }
    },
    methods: {
      handleResetForm(event) {
        event.preventDefault()
        const data = {...this.userData}
        if (this.btnActive) {
          console.log(data)
          this.isSuccess = true
        } else {
          console.log("Enter details")
        }
      }
    }
  }
</script>

<style lang="css" scoped>
	.login-content {
		width: 100%;
		max-width: 400px;
	}
	
	.logo-image {
		width: 180px;
	}
</style>
